.footer {
  // position: absolute;
  // bottom: 0;
  width: 100%;
  padding: 1rem var(--main-padding-lr);
  background: #efefef;

  // height: 35rem;
  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;

    .contact-items {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #70707073;
      margin-bottom: 3rem;

      .contact-item {
        flex: 1 0 0;
        display: flex;
        flex-direction: column;
        padding: 1rem 0 1rem 0;
        flex-wrap: wrap;
        min-width: 32rem;

        // &:first-child {
        //   padding-left: 0;
        // }

        // &:last-child {
        //   padding-right: 0;
        // }

        .title {
          font-weight: 400;
          // margin-bottom: 2rem;
        }

        .detail-wrapper {
          display: block
        }

        .detail {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          margin-top: 2rem;
          min-height: 80px;

          &.active {
            display: flex;
          }

          h3 {
            font-size: 1.6rem;
          }
        }

        .detail {
          padding: 0 1rem;
          border-left: 1px solid #70707073;
        }

      }
    }

    .other-info {
      position: relative;

      .company-info {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;

        span {
          margin-bottom: 1rem;
        }
      }

      .copyright,
      .credits {
        font-size: 1.3rem;
        margin-bottom: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 760px) {
  .footer {
    .wrapper {
      .contact-items {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .detail-box-sd {
          height: 100%;
          flex: 0.8;
        }

        .contact-item {
          min-width: 100%;

          .title {
            cursor: pointer;
            user-select: none;
          }

          .detail-wrapper {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 478px) {
  .footer {
    padding: 1rem var(--main-padding-lr-sd) 0;
  }
}

@media only screen and (max-width: 385px) {

  .footer .contact-items .contact-item h3,
  .footer .contact-items .other-info h3 {
    font-size: 1.5rem;
  }
}