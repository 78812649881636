.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: #efefef;
  text-transform: uppercase;
  letter-spacing: 3px;
  span {
    position: relative;
  }
  span::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 10px;
    background: var(--second-accent-color);
    animation: loader 1s linear infinite;
  }
}
@keyframes loader {
  0% {
    width: 0%
  }
  25% {
    width: 25%
  }
  50% {
    width: 50%
  }
  75% {
    width: 75%
  }
  100% {
    width: 100%
  }
}