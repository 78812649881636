:root {
  // fonts
  --large-font-family: 'Source Sans Pro', sans-serif;
  --middle-font-family: 'Merriweather', serif;
  --small-font-family: 'Nunito Sans', sans-serif;
  // colors
  --main-text-color: #333;
  --primary-color: #2B88BE;
  --primary-color-dark: #07689F;
  --secondary-color: #A2D5F2;
  --secondary-color-light: #C7E6F7;
  --third-color: #6ABFD6;
  --third-color-dark: #33869c;
  --first-accent-color: #FCDA81;
  --first-accent-color-dark: #FEC93C;
  --second-accent-color: #E94560;
  // padding
  --main-padding-lr: 50px;
  --main-padding-lr-sd: 20px;

  // max-width
  --max-width: 1440px;
  --min-width: 320px;

}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
  // scroll-behavior: smooth;
}

html,
body,
.App{
  position: relative;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--small-font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-text-color);
  background: var(--primary-color);
  letter-spacing: 1px;
}

a {
  text-decoration: none;
  color: var(--main-text-color);
  cursor: pointer;
}

h1,
h2 {
  font-family: var(--large-font-family);
  font-weight: 400;
}

h3,
h4 {
  font-size: 1.7rem;
  font-weight: 300;
}

h5 {
  font-weight: 300;
}

.center {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

// MainLayout
.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.main {
  position: relative;
  // height: calc(100vh - 11rem);
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--main-padding-lr);
  // take all the space available, so it pushes the footer stayed at the bottom
  flex: 1;
}

// Animation
.fade-in {
  opacity: 1 !important;
  animation: fade-in 0.3s ease-in-out;
}

@keyframes fade-in {
  from {
    transform: translateY(30%);
  }
  to {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 610px) {
  .main {
    padding: 0 var(--main-padding-lr-sd);
  }
}
@media only screen and (max-width: 478px) {
  // .main {
  //   padding: 0;
  // }
}
@media only screen and (max-width: 385px) {
  h3, h4{
    font-size: 1.5rem;
  }
}