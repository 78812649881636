.header {
  // height: 6rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: #fff;
  border-bottom: 1px solid #dfdfdf;
  z-index: 1000;
  overflow: hidden;

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem var(--main-padding-lr);
    height: 100%;

    .logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: var(--large-font-family);

      .company-img {
        width: 34px;
        margin-right: 0.5rem;

        img {
          width: 100%;
        }
      }

      .company-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h2 {
          position: relative;
          font-size: 1.7rem;
          margin: 0;
          font-weight: 700;

          &:nth-of-type(1)::before {
            content: "";
            position: absolute;
            top: 0;
            left: 36px;
            width: 35px;
            height: 100%;
            background: linear-gradient(90deg, #C6FFDD 10%, #FBD786, #F7797D);
            border-radius: 3px;
            z-index: -1;
            -webkit-animation: left-to-right 1s ease-in-out;
            -moz-animation: left-to-right 1s ease-in-out;
            animation: left-to-right 1s ease-in-out;

            @keyframes left-to-right {
              0% {
                left: -10px;
              }

              100% {
                left: 36px;
              }
            }
          }

          &:nth-of-type(2) {
            margin-top: 0.5rem;
            font-weight: 300;
          }
        }
      }
    }

    .contact-info {
      ul {
        display: flex;
        list-style: none;
        flex-direction: row;
        justify-content: center;
        align-self: center;
        margin: 0;
        padding: 0;

        li {
          margin-left: 40px;
        }

        li,
        a {
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          img {
            width: 23px;
          }

          span {
            font-size: 1.6rem;
            line-height: 1;
            padding: 0 8px;
          }
        }
      }
    }

    .menu-btn {
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      transition: all 0.5s ease;
      .line {
        width: 23px;
        height: 3px;
        background-color: var(--primary-color-dark);
        margin: 2px;
        border-radius: 10px;
      }

      &.active {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }
}

@media only screen and (max-width: 870px) {
  .header {
    .wrapper .contact-info ul li:first-child {
      display: none;
    }
  }
}

@media only screen and (max-width: 610px) {
  .header {
    .wrapper {
      padding: 1rem var(--main-padding-lr-sd);
      .contact-info ul li {
        display: none;
      }

      .menu-btn {
        display: flex;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .header .wrapper .logo {
    .company-img {
      width: 30px;
    }
    .company-name {
      h2 {
        font-size: 1.4rem;
      }
    }
  }
}