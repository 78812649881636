$animation-position-1: 42px;
$animation-position-2: 131px;
$animation-position-3: 257px;

.navbar {
  position: relative;
  // height: 5rem;
  background: #fff;
  user-select: none;
  .wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.5rem var(--main-padding-lr);
    height: 100%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15),
      0 3px 5px rgba(0, 0, 0, 0.1);

    ul {
      font-size: 1.6rem;
      position: relative;
      display: flex;
      // left: 15px;
      list-style: none;
      flex-direction: row;
      justify-content: center;
      align-self: center;
      margin: 0;
      padding: 0;

      .animation {
        position: absolute;
        bottom: -3px;
        height: 3px;
        background-color: var(--primary-color);
        border-radius: 6px;
        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
      }

      li {
        position: relative;
        padding: 5px;
        margin-left: 40px;
        font-size: 1.8rem;

        &.active a {
          color: var(--primary-color-dark);
        }

        &:nth-child(1):hover~.animation {
          left: $animation-position-1 !important;
          width: 42px !important;
        }

        &:nth-child(1).active~.animation {
          left: $animation-position-1;
          width: 42px;
        }

        &:nth-child(2):hover~.animation {
          left: $animation-position-2 !important; // previous left + previous width + margin: 40px
          width: 78px !important;
        }

        &:nth-child(2).active~.animation {
          left: $animation-position-2; // previous left + previous width + margin: 40px
          width: 78px;
        }

        &:nth-child(3):hover~.animation {
          left: $animation-position-3 !important;
          width: 78px !important;
        }

        &:nth-child(3).active~.animation {
          left: $animation-position-3;
          width: 78px;
        }

        a {
          cursor: pointer;
        }
      }
    }
  }
}

@supports not (scroll-behavior: smooth) {
  .navbar .wrapper ul li:nth-of-type(2){
    display: none;
  }
}
@media only screen and (max-width: 610px) {
  .navbar {
    display: none;
    position: sticky;
    top: 60px;
    width: 100%;
    z-index: 1000;

    &.navbar-sd {
      display: none;
    }
    .wrapper {
      padding: 0;
      width: 100%;

      ul {
        width: 100%;
        flex-direction: column;
        text-align: center;

        .animation {
          display: none;
        }

        li {
          position: relative;
          width: 100%;
          margin: 0;
          font-size: 1.8rem;
          
          a{
            display: inline-block;
            position: relative;
            width: 100%;
            padding: 1.5rem 0;
            
          }
          &:hover,
          &.active {
            background: var(--secondary-color-light);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 350px){
  .navbar {
    top: 55px;
  }
}